/* Fonts */

@font-face {
  font-family: Aclonica;
  src: url("./assets/fonts/Aclonica-Regular.ttf");
  font-weight: 700;
}

/* Default */

.title {
  font-weight: 700;
  color: rgb(28, 73, 50);
  font-size: 45px;
  display: flex;
  padding-top: 30px;
  padding-bottom: 30px;
  font-family: "Aclonica", sans-serif !important;
}

/* NavBar */

nav.navbar {
  padding: 10px 50px 10px 30px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32s ease-in-out;
}

nav.navbar.scrolled {
  padding: 10px 30px 10px 10px;
}

nav.navbar.small{
padding: 10px 5px 2px 10px;
}

.navbar-brand img {
  height: 60px;
}

.navbar-links {
  background-color: rgb(250, 250, 250, 0.5);
  border-radius: 3%;
}

.navbar-links .nav-link {
  text-align: center;
  font-weight: 700;
  color: rgb(28, 73, 50) !important;
  letter-spacing: 0.8px;
  font-size: 18px;
  opacity: 0.6;
}

.navbar-links .nav-link:hover {
  opacity: 0.8;
}

/* Banner */

.banner {
  margin-top: 0;
  padding: 300px 10px 80px 10px;
  background-image: linear-gradient(
      rgb(250, 250, 250, 0.2),
      rgba(250, 250, 250, 0.01)
    ),
    url("./assets/imgs/bg/golf-bg-2.png");
  width: 100%;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}

.about-parent {
  background-color: rgb(245, 225, 191, 0.2);
  height: 600px;
  padding-bottom: 20px;
  padding-top: 30px;
}

/* Tee Times */

.tee-time {
  background-color: rgb(245, 225, 191, 0.2);
  /* padding-top: 50px; */
  padding-bottom: 50px;
}

.tee-time-parent {
  background-color: rgb(28, 73, 50);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  padding: 20px 20px 20px 20px !important;
  margin-top: 10px !important;
  border-radius: 12px;
}

.tee-time-holder {
  background-color: rgb(250, 250, 250, 0.7);
  border-radius: 8px;
  padding: 0px 0px 10px 0px;
}

.tee-time-cal {
  margin-bottom: -8px;
}

.tee-time-btn {
  padding: 5px 10px 0px 10px;
}

.overlay-container {
  position: relative;
}

.background-image-history {
  z-index: 1;
  opacity: 0.9;
  height: 400px;
  width: 220px;
  border-radius: 8px;
  position: absolute;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  top: 10px;
  left: 50px;
}

.overlay-summary-title-history {
  z-index: 2;
  position: absolute;
  border-radius: 8px;
  top: 20px;
  left: 10px;
  color: rgb(28, 73, 50);
  background-color: rgb(245, 242, 218);
  padding: 10px;
  font-weight: 700;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}

.overlay-summary-writing-history {
  z-index: 2;
  position: absolute;
  border-radius: 8px;
  top: 270px;
  left: 150px;
  color: rgb(28, 73, 50);
  background-color: rgb(245, 242, 218);
  padding: 10px;
  font-weight: 500;
  font-size: 12px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  max-width: 300px;
}

.background-image-community-1 {
  z-index: 1;
  opacity: 0.7;
  height: 240px;
  width: 320px;
  border-radius: 8px;
  position: absolute;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  top: 10px;
  left: 50px;
}
.background-image-community-2 {
  z-index: 2;
  height: 140px;
  width: 200px;
  border-radius: 8px;
  position: absolute;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  top: 200px;
  left: 0px;
}

.overlay-summary-title-community {
  z-index: 2;
  position: absolute;
  border-radius: 8px;
  top: 20px;
  left: 10px;
  color: rgb(28, 73, 50);
  background-color: rgb(245, 242, 218);
  padding: 10px;
  font-weight: 700;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}

.overlay-summary-writing-community {
  z-index: 2;
  position: absolute;
  border-radius: 8px;
  top: 270px;
  left: 150px;
  color: rgb(28, 73, 50);
  background-color: rgb(245, 242, 218);
  padding: 10px;
  font-weight: 500;
  font-size: 12px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  max-width: 300px;
}

/* Confirmation */

.confirmation {
  width: 25rem;
  padding: 4px 10px 0px 10px;
}

.confirmation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
}

.confirmation-card-img-top {
  margin-top: 4px;
  border-radius: 4px;
}

.confirmation-subtitle {
  background-color: rgb(255, 255, 0, 0.3);
  padding: 4px;
}

/* Memberships */

.membership-parent {
  background-color: rgb(245, 225, 191, 0.2);
  padding-bottom: 20px;
  padding-top: 30px;
}

/* Membership */

.membership {
  padding: 10px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  width: 22rem;
}

.membership .card-img-top{
  height: 200px;
  opacity: .9;
}

.membership .card-title {
  text-align: center;
}
.membership .card-text {
  text-align: center;
  padding-bottom: 0px;
}

.membership .list-group {
  padding-bottom: 12px;
}

.membership .membership-group-item {
  font-weight: 500;
  font-size: 14px;
  padding: 20px 14px 14px 20px;
}
.membership .membership-group-item img {
  padding-right: 6px;
}

/* Contact Us */

.contact-us {
  background-color: rgb(245, 242, 218, 0.4);
  padding: 0px 14px 14px 20px;
  margin-top: 10px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  height: 98%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.contact-us .mini-title {
  color: rgb(28, 73, 50);
  font-weight: 700;
  padding-bottom: 10px;
  font-size: 25px;
}

.contact-us-btn {
  padding: 5px 10px 0px 10px;
}

/* Footer */

.footer{
  display: flex;
  background-color: rgb(245, 242, 218, 0.4);
  padding: 20px 2px 20px 20px;
}

.footer img{
  opacity: .5;
}

/* Media Queries */

/*  Medium */
@media (max-width: 768px) {
}

/* Small */
@media (max-width: 576px) {
  .overlay-container {
    display: flex;
    left: 5px;
  }

  .background-image-history {
    height: 400px;
    width: 200px;
    top: 10px;
    left: 50px;
  }
  .overlay-summary-writing-history {
    top: 330px;
    left: 10px;
    padding: 10px;
  }

  .overlay-summary-title-community{
    top: 480px;
  }

  .overlay-summary-writing-community{
    top: 640px;
    left: 10px
  }

  .background-image-community-1 {
    height: 200px;
    width: 280px;
    top: 470px;
    left: 50px;
  }

  .background-image-community-2 {
    display: none;
  }

  .membership-parent {
    padding-top: 260px;
  }

  .title{
    font-size: 35px;
    padding: 10px 0px 0px 15px!important;
  }
  .tee-time-parent{
    margin-bottom: 20px;
  }
}
